.edit-tools {
  position: fixed;
  z-index: 500;
  left: 0.5rem;
  bottom: 0.5rem;
  background: $green;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.6rem;
  color: $white;

  .button {
    background: darken($green, 15%);
    font-size: 1em;
    margin: 0.25rem;
    padding: 0.5rem 0.6rem;
    display: inline-block;
    min-width: auto;
    border-radius: 0.6rem;
  }
}

.show-breakpoint {
  position: fixed;
  opacity: 0.7;
  z-index: 10;
  right: 0.5rem;
  bottom: 4rem;
  color: $white;
  font-weight: bold;
  > div {
    padding: 0.1em 0.5em;
    border-radius: 0.6rem;
  }
  .show-for-small-only {
    background: lightseagreen;
  }
  .show-for-medium-only {
    background: orange;
  }
  .show-for-large {
    background: red;
  }
}

;@import "sass-embedded-legacy-load-done:137";